import { template as template_af8117fa43b94119a21f92d8f10b8ce9 } from "@ember/template-compiler";
const FKLabel = template_af8117fa43b94119a21f92d8f10b8ce9(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
