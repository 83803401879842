import { template as template_5e68378f43ac440f9bc32ac300a8854e } from "@ember/template-compiler";
const SidebarSectionMessage = template_5e68378f43ac440f9bc32ac300a8854e(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
