import { template as template_1c7cfea51db04a53ace58f5f16f3be66 } from "@ember/template-compiler";
const WelcomeHeader = template_1c7cfea51db04a53ace58f5f16f3be66(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
