import { template as template_428735028576415fb497c5e1a07c06a6 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_428735028576415fb497c5e1a07c06a6(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
